module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HORIKO CAPITAL MANAGEMENT LLC","short_name":"HORIKO CAPITAL MANAGEMENT","description":"ニューヨークに拠点を置く投資顧問会社、ホリコ・キャピタル・マネジメントの公式ホームページです。","icon":"src/images/favicon.png","start_url":"/","background_color":"#0b4a86","theme_color":"#fff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c257bb3821436a878e91c20c24781aa6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
